// Import all of Bootstrap's CSS

$font-family: "Montserrat";
$font-family-sans-serif: "Montserrat";
$btn-border-radius-lg: 0.375rem;
$input-font-size: 1.125rem;
$input-padding-y: 0.85rem;
$input-line-height: 1.05;
$input-border-radius: 3px;
$input-border-color: #b2b2b2;
$input-border-width: 1px;
$input-focus-border-color: rgb(128, 130, 133);
$input-focus-box-shadow: 0;
$input-focus-width: 0;

// broker colours
$orange: #f18d1e;
$yellow: #d0b508;
$blue: #0081c3;
$dark-blue: #002e6d;

// link brand variables
$primary: $dark-blue;
$primary-hover: #031f45;
$secondary: white;
$gray-100: #efeded;
$gray-800: #002e6d;
$gray-600: #adb5bd;
$success: $primary;
$info: $yellow;
$warning: $orange;
$info-button: $dark-blue;
$font-color: black;
$footer-color: white;
$footer-links-color: black;
$header-color: white;

.content-container {
    min-height: 630px;
}

@import "bootstrap/scss/bootstrap";

.header-big {
    font-weight: 600 !important;
}

.btn {
    border-radius: 3px !important;
    border-width: 1px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}

.btn-lg {
    border-width: 1px !important;
}

.btn-outline-secondary {
    color: $gray-600 !important;
    background-color: white !important;
    border-color: $gray-600 !important;
}

.btn-outline-secondary:hover {
    color: black !important;
    background-color: $gray-600 !important;
    border-color: $gray-600 !important;
}

.btn-outline-primary {
    color: $primary !important;
    background-color: white !important;
    border-color: $primary !important;
}

.btn-outline-primary:hover {
    color: white !important;
    background-color: $primary !important;
    border-color: $primary !important;
}

.btn-outline-info {
    color: $info-button !important;
    background-color: white !important;
    border-color: $info-button !important;
}

.btn-outline-info:hover {
    color: white !important;
    background-color: $info-button !important;
    border-color: $info-button !important;
}

.btn-primary {
    color: white !important;
    background-color: $primary !important;
    border-color: $primary !important;
    border-radius: 3px !important;
}

.btn-primary:hover {
    background-color: $primary-hover !important;
    border-color: $primary-hover !important;
}

.phone-button {
    color: white !important;
    background-color: $primary;
    position: relative !important;
    top: 1.5rem !important;
    right: 15% !important;
    margin-left: 50%;
}

.phone-button:hover {
    color: white !important;
    background-color: $primary-hover !important;
    border-color: $primary-hover !important;
}

input {
    font-size: 18px !important;
    font-weight: 400 !important;
}

input::placeholder {
    color: gray !important;
}

//the colour of the phone and @ symbols on the landing page
// .input-group-text {
//     color: rgb(128, 130, 133);
//     background-color: white !important;
// }

.form-floating {
    color: transparent !important;
}

.form-floating:focus {
    color: gray !important;
}

.form-select {
    font-size: 18px !important;
    font-weight: 400 !important;
}

label {
    font-weight: 700 !important;
}

.form-label {
    font-weight: 700 !important;
}

.form-check-label {
    font-weight: 400 !important;
}

.dropdown-toggle {
    color: white !important;
    background-color: $blue !important;
    border-color: $blue !important;
    font-weight: 700 !important;
}

.dropdown-toggle:hover {
    color: white !important;
    background-color: $dark-blue !important;
    border-color: $dark-blue !important;
}

.btn-success {
    background-color: $primary;
    border-color: $primary;
    color: white;
}

h4 {
    text-transform: uppercase;
}

h5 {
    position: relative !important;
    top: 1.5rem !important;
    margin-bottom: 2rem !important;
}

a,
a:focus,
button,
button:focus {
    outline: none;
}

.clicker {
    cursor: pointer;
}

.forbidden {
    opacity: 0.5;
    background-color: dimgrey;
    color: linen;
    cursor: not-allowed;
}

.full-square {
    width: 100%;
    border-radius: 3px !important;
}

.accordion-button {
    width: 100%;
    font-size: 1rem !important;
    align-items: center;
    border: 0;
    border-radius: 3px !important;
    display: flex;
    overflow-anchor: none;
    position: relative;
    text-align: left;
}

.accordion-header {
    background-color: rgb(0,0,0,0) !important;
}

.brokerVideo {
    width: 320px;
    height: 180px;
}

.form-label {
    font-weight: 600;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
}

.desktop-menu {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    font-size: 1rem !important;
}

.menu {
    background-color: $header-color !important;
    color: $font-color !important;
    min-height: 5rem;
    background-size: contain;
}

.footer-area {
    background-color: $footer-color;
    color: $font-color;
}

.footer-area a:link,
.footer-area a:visited {
    text-decoration: none;
    color: $footer-links-color;
}

.footer-area a:hover {
    text-decoration: none;
    color: $primary !important;
}

.footer-secure {
    color: $font-color !important;
    border-top: 1px #c8c9cb solid;
    padding-top: 1.5rem;
    margin-left: 18%;
    margin-right: 18%;
}

.form-range {
    border-width: 0 !important;
}

.bg-warning {
    background-color: $warning !important;
}

.broker-logo {
    position: fixed;
    top: 0px;
    left: 5% !important;
    height: 150px;
}

.broker-logo-mobile {
    height: 4.12rem;
}